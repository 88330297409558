import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useReactToPrint } from "react-to-print";
import { Btn } from "../../../AbstractElements";
import ConfirmationModal from "../../UiKits/ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";
import {
  useGetOrdersQuery,
  useOrderDetailsMutation,
  useAddStatusMutation,
} from "../../../features/ordersApiSlice";

const Orders = () => {
  const [data, setData] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [selectedRow, setSelectedRow] = useState();
  const [toggleSelect, setToggleSelect] = useState(false);

  const [getOrderDetails] = useOrderDetailsMutation();
  const [addStatus] = useAddStatusMutation();

  const [order, setOrder] = useState({
    orderNo: "",
    date: "",
    first_name: "",
    last_name: "",
    totalPrice: "",
    status: "",
    duration: "",
    transactionId: "",
    paymentMethod: "",
    paymentStatus: "",
    amount: "",
    customer_quiz_id: 1,
  });

  const [shipAddress, setShipAddress] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    address: "",
    appartment: "",
    state: "",
    zipCode: "",
    phoneNo: "",
    email: "",
  });

  const [status, setStatus] = useState("");
  const [details, setDetails] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [showError, setShowError] = useState({ status: "", details: "" });

  const shippingAddressRef = useRef();

  const {
    data: fetchOrders,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetOrdersQuery({ skip: false });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        console.log(fetchOrders);
        setData(fetchOrders.data);
      }
    };
    fn();
  }, [fetchOrders]);

  const handleRowSelected = useCallback((state) => {
    // setSelectedRow(state.selectedRows);
    // //console.log(selectedRow)
    //
    // if(state.selectedCount === 0) {
    //     reset();
    // }
  }, []);

  const handleAction = async (row) => {
    console.log(row);

    try {
      setSelectedRow(row);
      const res = await getOrderDetails({ order_id: row.id }).unwrap();

      console.log(res.status);
      setOrder(res.data[0]);
      setShipAddress(res.shippingAddress[0]);
      setIngredients(res.ingredients);
      setStatuses(res.status);

      //console.log(res.ingredients)
    } catch (e) {
      console.log(e);
    }
  };

  const toggleModal = () => {
    showErrorMessage("status", "");
    showErrorMessage("details", "");

    if (status === "") {
      showErrorMessage("status", "Please select status.");
    } else {
      if (selectedRow) {
        setModalOpen(!modalOpen);
      }
    }
  };

  const showErrorMessage = (object, message) => {
    setShowError((prevValues) => ({
      ...prevValues,
      [object]: message,
    }));
  };

  const handleConfirmAction = () => {
    handleUpdateStatus();
  };

  const handleUpdateStatus = async () => {
    console.log(status, details);
    console.log(selectedRow.id);

    try {
      const res = await addStatus({
        order_id: selectedRow.id,
        status: status,
        details: details,
      }).unwrap();

      setStatuses(res.status);

      console.log(res);

      // setOrder(res.data[0])
      // setShipAddress(res.shippingAddress[0])
      // setIngredients(res.ingredients)
    } catch (e) {
      console.log(e);
    }
  };

  const printShipAddress = useReactToPrint({
    content: () => shippingAddressRef.current,
  });

  const tableColumns = [
    {
      name: "Order#",
      selector: (row) => `${row.id}`,
      width: "80px",
    },
    {
      name: "Invoice Date",
      selector: (row) =>
        row["invoice_date"] ? row["invoice_date"] : row["createdAt"],
      sortable: true,
      center: false,
      width: "160px",
    },
    {
      name: "Name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
      center: false,
      headerStyle: (selector, id) => {
        return { textAlign: "left" };
      },
    },
    {
      name: "Preparation Date",
      selector: (row) => row["preparation_date"],
      sortable: true,
      center: false,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      center: false,
    },
    {
      name: "Subscription",
      selector: (row) => row["duration"],
      sortable: true,
      center: false,
    },
    {
      name: "Amount",
      selector: (row) => row["totalPrice"],
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      width: "140px",
      cell: (row) => (
        <Btn attrBtn={{ color: "primary", onClick: () => handleAction(row) }}>
          {"View"}
        </Btn>
      ),
    },
  ];

  return (
    <Fragment>
      <h1>Orders</h1>
      <Container fluid={true}>
        <Card>
          <CardBody>
            <DataTable
              columns={tableColumns}
              data={data}
              striped={true}
              center={true}
              pagination
              //selectableRows
              //selectableRowsSingle
              selectableRowsHighlight
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleSelect}
              //paginationPerPage={2}  // Display 5 rows per page
              //paginationRowsPerPageOptions={[10]}  // Allow user to choose 10 rows per page
              paginationComponentOptions={{
                noRowsPerPage: false, // Hide the dropdown that allows changing the number of rows per page
              }}
            />
          </CardBody>
        </Card>

        <Row>
          <Col sm="7">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="text-start">
                      <h3>
                        Order #{" "}
                        <span className="digits counter">{order.id}</span>
                      </h3>
                      <p>
                        Date: {order.date}
                        <br />
                        Amount: <span className="digits">{order.amount}</span>
                        <br />
                        Subscription:{" "}
                        <span className="digits">{order.duration}</span>
                        <br />
                        Current Status:{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {order.status}
                        </span>
                        &nbsp;
                        {order.details}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 text-end">
                    <div ref={shippingAddressRef} className="m-2 mt-0">
                      <h3>Shipping Address</h3>
                      <p>
                        {shipAddress.companyName}
                        <br />
                        {shipAddress.firstName}&nbsp;{shipAddress.lastName}
                        <br />
                        {shipAddress.appartment}
                        <br />
                        {shipAddress.address}
                        <br />
                        {shipAddress.state}&nbsp;({shipAddress.zipCode})
                        <br />
                        {shipAddress.phoneNo}
                      </p>
                    </div>
                    <Btn
                      attrBtn={{
                        className: "mt-3",
                        color: "primary",
                        onClick: () => printShipAddress(),
                      }}
                    >
                      <i class="fa fa-print me-1" /> {"Print"}
                    </Btn>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <div className="col-sm-6">
                    <h4>Add Status</h4>
                    <select
                      className="form-control"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">--Select Status--</option>
                      <option value="Under Process">Under Process</option>
                      <option value="Ready to dispatch">
                        Ready to dispatch
                      </option>
                      <option value="Dispatched">Dispatched</option>
                    </select>
                    {showError.status === "" ? (
                      ""
                    ) : (
                      <span className="text-danger">Please select status</span>
                    )}
                    <input
                      type="text"
                      onChange={(e) => {
                        e.preventDefault();
                        setDetails(e.target.value);
                      }}
                      className="form-control mt-3"
                    />

                    <Btn
                      attrBtn={{
                        className: "mt-3",
                        color: "primary",
                        onClick: () => toggleModal(),
                      }}
                    >
                      {"Update Status"}
                    </Btn>
                  </div>
                  <div className="col-sm-6 text-end">
                    <h4>Status List</h4>
                    <table className="table text-start">
                      <tbody>
                        {statuses &&
                          statuses.map((element, i) => (
                            <tr>
                              <td>{element.status}</td>
                              <td>{element.details}</td>
                              <td>{element.date}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm="5">
            <Card>
              <CardBody className="personalized-formula">
                <div className="table-responsive">
                  <table className="table accordion" id="accordionExample">
                    <thead>
                      <tr>
                        <th scope="col" className="col-8">
                          Nutrient
                        </th>
                        <th scope="col">Quantity</th>
                        <th scope="col">% NRV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ingredients &&
                        ingredients.map((item) => (
                          <tr>
                            <td>{item.ingredient}</td>
                            <td>{item.daily_dose} mg</td>
                            <td>
                              {item.per_eu_nrv === 0
                                ? "*"
                                : `${item.per_eu_nrv}%`}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ConfirmationModal
          message={"Are you sure to update the status?"}
          isOpen={modalOpen}
          toggle={toggleModal}
          onConfirm={handleConfirmAction}
        />
        <ToastContainer />
      </Container>
    </Fragment>
  );
};

export default Orders;
